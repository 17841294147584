import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "./../components/Layout";
import { PageWrapperDiv } from "./../components/PressStyles/elements";
import Perspectives from "./../components/Perspectives";
// import { WhiteRecommendationBlock } from "./../components/RecommendationBlock";

// TODO: make it so these recommendations can be editorial

const HeaderImageWrapper = styled.div`
  position: relative;
  height: 610px;
  width: var(--width);
  z-index: 1;
  & > div:first-of-type {
    pointer-events: none;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: var(--red);
      mix-blend-mode: multiply;
    }
    & + div {
      display: flex;
      padding: var(--outerMargin);
      position: absolute;
      bottom: 20px;
      color: var(--white);
      z-index: 3;
      & h1 {
        color: var(--green);
        font-size: 180px;
        font-weight: normal;
        margin: 0;
        white-space: nowrap;
        & + div {
          margin-left: 86px;
          & h2 {
            color: var(--green);
            margin: 60px 0 0 0;
            font-size: 62px;
            line-height: 68.2px;
            font-weight: 300;
            margin-bottom: 35px;
          }
          & p {
            margin: 0;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: 0.32px;
            & a {
              color: var(--white);
              border-bottom: 2px solid var(--white);
            }
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    --outerMargin: 50px;
  }
  @media (max-width: 1023px) {
    --outerMargin: 25px;
    & > div:first-of-type + div {
      flex-direction: column;
      bottom: 0;
      & h1 {
        font-size: 120px;
        line-height: 120px;
        & + div {
          margin-left: 0;
          & h2 {
            margin-top: 0;
          }
        }
      }
    }
  }
  @media (max-width: 1023px) {
    --outerMargin: 25px;
    & > div:first-of-type + div {
      & h1 {
        font-size: 72px;
        line-height: 72px;
        & + div {
          & h2 {
            font-size: 36px;
            line-height: 42px;
            & + div p {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
`;
const NotFoundPage = () => {
  const notFoundPageData = useStaticQuery(graphql`
    {
      craftNotFoundPageNotFoundPageEntry {
        richTextField
        recommendationEvents {
          slug
          title
          typeHandle
          ... on Craft_articles_words_Entry {
            editorialAuthor
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_videos_Entry {
            editorialAuthor
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_images_Entry {
            editorialAuthor
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_sounds_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_event_event_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_community_communityMember_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        title
        headerImage {
          ... on Craft_editorialAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440, height: 610, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `).craftNotFoundPageNotFoundPageEntry;
  const { title, richTextField, recommendationEvents, headerImage } =
    notFoundPageData;
  return (
    <Layout
      backgroundColor={"var(--white)"}
      footerColor={"var(--green)"}
      skipHeader
      title={title}
      SEODescription={richTextField}
    >
      <PageWrapperDiv>
        <HeaderImageWrapper>
          <Img fixed={headerImage[0].wideImage.childImageSharp.fixed} />
          <div>
            <h1>404</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: `<h2>${title}</h2><div>${richTextField}</div>`,
              }}
            />
          </div>
        </HeaderImageWrapper>
        <div style={{ padding: "var(--outerMargin)" }}>
          <Perspectives
            tiles={recommendationEvents}
            title={"Our recommendations"}
          />
        </div>

        {/*<WhiteRecommendationBlock
          events={recommendationEvents}
          noButton
          title={"Our recommendations"}
				/>*/}
      </PageWrapperDiv>
    </Layout>
  );
};

export default NotFoundPage;
